import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import Changelog from '@twilio-paste/card/CHANGELOG.md';
import Img from 'gatsby-image';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/card"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    primaryButtonDo: file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "images/do1-primary-button.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    primaryButtonDont: file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "images/dont1-primary-button.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headingInCardDo: file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "images/do2-heading-in-card.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    multiplePrimaryDont: file(
      sourceInstanceName: {eq: "assets"}
      relativePath: {eq: "images/dont2-multiple-primary.png"}
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/card/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Card" categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/blob/main/packages/paste-core/components/card/" storybookUrl="/?path=/story/components-card--default" abstractUrl="https://share.goabstract.com/6a1699c0-a54a-47fc-9a4e-84d23b4e42fc?" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Cards`}</h3>
        <p>{`Cards are specifically-styled containers that group relatable content and actions. Card is an extremely
flexible container that does not require specific components inside of it. You can compose a Card to support your use case,
but elements such as `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{`, `}<a parentName="p" {...{
            "href": "/components/paragraph"
          }}>{`Paragraph`}</a>{`, and `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button`}</a>{` or `}<a parentName="p" {...{
            "href": "/components/anchor"
          }}>{`Anchor`}</a>{` are commonly used.
See `}<a parentName="p" {...{
            "href": "#when-to-use-a-card"
          }}>{`When to use a Card`}</a>{` and `}<a parentName="p" {...{
            "href": "#examples"
          }}>{`Examples`}</a>{` for common Card patterns and dos/donts.`}</p>
        <p>{`A Card does not handle any interactive events such as hover, click, or focus, though children
composed inside of it may commonly have event handlers.`}</p>
        <p>{`Acknowledging the flexibility of Card component, there are several `}<a parentName="p" {...{
            "href": "#anatomy"
          }}>{`non-negotiable properties`}</a>{`
of a Card that differentiate it from a more basic page-layout element, such as `}<a parentName="p" {...{
            "href": "/primitives/box"
          }}>{`Box`}</a>{` including background color, border width, border radius, and border color.`}</p>
        <h3>{`Card vs. Box`}</h3>
        <p>{`At its core, Card is a `}<a parentName="p" {...{
            "href": "/primitives/box"
          }}>{`Box`}</a>{` with specific styling attributes and more explicit use cases that you can find in `}<a parentName="p" {...{
            "href": "#examples"
          }}>{`Examples`}</a>{`.
If you find yourself limited by the default styling and constraints of a Card, you may consider using a Box instead,
but first consider bringing the problem you are trying to solve
to Design System Office Hours to see if another component or pattern could fit your needs.`}</p>
        <h3>{`Examples`}</h3>
        <h4>{`Basic Card`}</h4>
        <p>{`An example of a Card with default padding.`}</p>
        <LivePreview scope={{
          Card,
          Paragraph,
          Heading,
          Anchor
        }} language="jsx" mdxType="LivePreview">
  {`<Card>
  <Heading as="h2" variant="heading20">Parable of the Talents</Heading>
  <Paragraph>
    Choose your leaders with wisdom and forethought. To be led by a coward is to be controlled by all that the coward fears. To be led by a fool is to be led by the opportunists who control the fool. To be led by a thief is to offer up your most precious treasures to be stolen. To be led by a liar is to ask to be lied to. To be led by a tyrant is to sell yourself and those you love into slavery.
  </Paragraph>
  <Paragraph marginBottom="space0">— <Anchor href="https://www.goodreads.com/book/show/60932.Parable_of_the_Talents">Octavia Butler</Anchor></Paragraph>
</Card>`}
        </LivePreview>
        <h4>{`Card with Title, Body and Button`}</h4>
        <p>{`One of the most common use cases for a Card is to relate a title (`}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{`), supporting body copy (`}<a parentName="p" {...{
            "href": "/components/paragraph"
          }}>{`Paragraph`}</a>{`),
and primary action (`}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button`}</a>{`) together. Relating these three elements together with a Card makes it easy for a user to
digest and provides a clear call to action. Padding surrounding the inner content of a Card can be
adjusted to suit the needs of your implementation.`}</p>
        <LivePreview scope={{
          Card,
          Paragraph,
          Heading,
          Button
        }} language="jsx" mdxType="LivePreview">
  {`<Card padding="space120">
  <Heading as="h2" variant="heading20">This is a card with a primary action</Heading>
  <Paragraph>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dui tellus, tristique in tincidunt cursus, efficitur
    at felis. Phasellus imperdiet, lorem et commodo egestas, arcu.
  </Paragraph>
  <Button variant="submit"> I am a primary action button </Button>
</Card>`}
        </LivePreview>
        <h4>{`Card with Centered Content`}</h4>
        <p>{`Your implementation use case may call for a Card with centered content. You can accommodate this by
using the alignment props available on some components, or by creating a custom layout inside your
Card using Box or Flex.`}</p>
        <LivePreview scope={{
          Card,
          Paragraph,
          Heading,
          Button,
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<Card padding="space200">
  <Text as="div" textAlign="center">
    <Heading as="h2" variant="heading20">
      Let's verify your integration
    </Heading>
    <Paragraph>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </Paragraph>
    <Button variant="submit"> Test my integration </Button>
  </Text>
</Card>;`}
        </LivePreview>
        <h2>{`Composing a Card`}</h2>
        <h3>{`When to use a Card`}</h3>
        <p>{`Cards are a great tool for relating a concise amount of related information together in one object, much like a business card or baseball card in real life.`}</p>
        <p>{`For example, you might use a single Card as a summary for a feature whose call to action encourages a user to dive deeper. Alternatively, multiple Cards on a single page with hierarchy of equal weights could be used to highlight multiple choices or paths a user can take.`}</p>
        <p>{`Ultimately, Card is a very flexible component that can fit a variety of needs. If you feel that you have a unique use case or are limited by the Card implementation, please visit Design System Office Hours to see how we can help address your needs.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Have a clear call to action when including Buttons. Avoid including more than one primary and one secondary button." preview={false} mdxType="Do">
    <Img fluid={props.data.primaryButtonDo.childImageSharp.fluid} mdxType="Img" />
  </Do>
  <Dont title="Don't" body="Don’t put multiple primary actions in a Card" preview={false} mdxType="Dont">
    <Img fluid={props.data.primaryButtonDont.childImageSharp.fluid} mdxType="Img" />
  </Dont>
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use a Heading in your Card that appropriately reflects the information architecture of the page." preview={false} mdxType="Do">
    <Img fluid={props.data.headingInCardDo.childImageSharp.fluid} mdxType="Img" />
  </Do>
  <Dont title="Don't" body="Don’t use Cards to highlight multiple primary actions on a single page." preview={false} mdxType="Dont">
    <Img fluid={props.data.multiplePrimaryDont.childImageSharp.fluid} mdxType="Img" />
  </Dont>
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use a Heading to set a clear expectation on the Card’s purpose." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t place multiple Heading components in a single Card." preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use a consistent location on the bottom of the Card for primary actions or next steps." preview={false} mdxType="Do" />
        </DoDont>
        <h2>{`Anatomy`}</h2>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-lighter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-60`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`Installation`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/card - or - yarn add @twilio-paste/core
`}</code></pre>
        <h3>{`API`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`React.ReactNode`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Child components to render into the card`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`undefined`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Spacing`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Internal spacing of the card`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space60`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`globalHtmlAttrs`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Any html attrs like aria, data etc.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`undefined`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      